<template>
  <header
    class="head"
    :class="isDetail ? 'black-head' : ''"
    :style="{ background: backgroundColor }"
  >
    <el-menu
      router
      mode="horizontal"
      :background-color="backgroundColor"
      :text-color="textColor"
      active-text-color="#165DFF"
      class="container"
      :default-active="activeIndex"
      @select="handleSelect"
    >
      <!-- logo -->
      <el-menu-item>
        <img class="logo-img" src="@/assets/images/logo.png" />
      </el-menu-item>
      <!-- menu -->
      <el-menu-item class="head_menu" v-for="(item, i) in navList" :key="i" :index="item.path">
        <span>{{ item.name }}</span>
      </el-menu-item>
    </el-menu>
  </header>
</template>

<script>
export default {
  name: "NavMenu",

  data() {
    return {
      navList: [
        { path: "/", name: "首页" },
        { path: "/dataset", name: "数据集" },
        { path: "/model", name: "模型" },
        { path: "/leaderboard", name: "排行榜" },
        { path: "/CTCMB", name: "CTCMB" },
        { path: "/FIN", name: "FIN" },
        { path: "/About", name: "关于" },
      ],
      scrollPosition: 0,
      isDetail: false,
      isScorll: false,
      activeIndex: "/",
      rankList: [
        {
          text: "中医排行榜(CTCMB)",
          path: "CTCMB",
        },
        {
          text: "医疗排行榜（Med）",
          path: "med",
        },
        {
          text: "机器人排行榜（Robot）",
          path: "robot",
        },
        {
          text: "金融排行榜（Finance）",
          path: "finance",
        },
        {
          text: "硬件排行榜（Hardware）",
          path: "hardware",
        },
        {
          text: "代码排行榜（Code）",
          path: "code",
        },
      ],
    };
  },
  watch: {
    // 监听整个 $route 对象
    $route: function (to, from) {
      // to 和 from 分别代表新旧路由


      // 激活菜单逻辑 
      if (to.path.includes("/leaderboard")) {
        this.activeIndex = "/leaderboard";
      } else if (to.path.includes("/dataset")) {
        this.activeIndex = "/dataset";
      } else if (to.path.includes("/model")) {
        this.activeIndex = "/model";
      } else {
        this.activeIndex = to.path;
      }

      
      console.log("路由变化了 nanva", to, from);
      // balckThemeArr 导航栏需要黑色主题的数组
      const balckThemeArr = ["/dataset-detail", "/CTCMB","/FIN"];
      this.isDetail = balckThemeArr.includes(to.path);


      // 手动输入LeaderBoard 模块逻辑监听 leaderBoard/code
      if(to.params.path){
        const target = this.rankList.find(item=>item.path == to.params.path)
        localStorage.setItem("leaderboardPath", target.path);
        localStorage.setItem("leaderboardName", target.text);
      }else {
        localStorage.removeItem("leaderboardPath");
        localStorage.setItem("leaderboardName", '大语言模型榜单')
      }


      console.log("this.isDetail", this.isDetail);
    },
  },

  computed: {
    backgroundColor() {
      return this.isDetail
        ? "#1d2129"
        : this.isScorll
        ? "#1d2129"
        : "rgba(255, 255, 255,0.2)";
    },
    textColor() {
      return this.isDetail ? "#fff" : this.isScorll ? "#fff" : "#1d2129";
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == "/leaderboard") {
        localStorage.setItem("leaderboardName", "大语言模型榜单");
        localStorage.removeItem("leaderboardPath");
      }
    },

    updateScroll() {
      this.isScorll = window.scrollY >= 960;
    },
  },
  mounted() {
    // 使用箭头函数确保 this 上下文正确
    window.addEventListener("scroll", this.updateScroll, true);
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener("scroll", this.updateScroll, true);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-menu.el-menu--horizontal {
  border-bottom: 0 none;
}

::v-deep .el-menu--horizontal > .el-menu-item:first-child {
  padding: 0 16px 0 0 !important;
}
.head {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  transition: background-color 0.3s ease;
  .container{
    height: 100%;
  }
}
.logo-img {
  width: 170px;
  height: 48px;
}
.black-head {
  background: #1d2129 !important;
  ::v-deep .el-menu--horizontal > .el-menu-item {
    color: #fff !important;
    background: #1d2129 !important;
  }

  ::v-deep .el-menu {
    background: #1d2129 !important;
  }

  ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
    color: rgb(22, 93, 255) !important;
  }
}
.el-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
.head_menu{
  font-size:medium;
}
</style>

